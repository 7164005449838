import React from 'react'
import { Typography } from '@mui/material'

export type BlockquoteProps = BASE.Helpers.IReactDefaultProps & {
  type?: 'blockquote'
}

export default function Blockquote({
  type = 'blockquote',
  children,
  sx,
}: BlockquoteProps) {
  return (
    <Typography
      component={type}
      sx={[
        (theme) => ({
          p: {
            textIndent: '20px',
            position: 'relative',
            marginBottom: '16px',
            listStyleType: 'none',
            fontStyle: 'italic',
          },
          'p::before': {
            position: 'absolute',
            content: '"\\00BB"',
            color: 'red',
            fontSize: '40px',
            left: '25px',
            top: '-20px',
            fontWeight: 'bold',
            fontStyle: 'normal',
          },
          'p::after': {
            content: '"\\00AB"',
            color: 'red',
            fontSize: '40px',
            lineHeight: '1px',
            paddingLeft: '5px',
            fontWeight: 'bold',
            fontStyle: 'normal',
          },
          marginBottom: theme.spacing(6),
          position: 'relative',
          paddingLeft: theme.spacing(12),
          paddingRight: theme.spacing(12),
          [theme.breakpoints.up('lg')]: {
            marginBottom: theme.spacing(10),
          },
        }),
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      {children}
    </Typography>
  )
}
